import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { shouldDisplayLock } from "../functions/helperFunctions";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

function Visit() {
	const navigate = useNavigate();
	const [passwordIsCorrect, setPasswordIsCorrect] = useState(null);
	useEffect(()=>{
		if(!shouldDisplayLock()){
			navigate("../");
		}
	},[]);

	const handleSubmit = (e) => {
		e.preventDefault();
		const data = new FormData(e.currentTarget);
		axios({
			method: "POST",
			url: "/password_is_correct/",
			data: {
				password: data.get("password"),
			},
		})
			.then((response) => {
				setPasswordIsCorrect(response.data.password_is_correct);
				if (response.data.password_is_correct) {
					localStorage.setItem("has_access", true);
					navigate("/");
				}
			})
			.catch((error) => {
				if (error.response) {
					console.log(error.response);
					console.log(error.response.status);
					console.log(error.response.headers);
				}
			});
	};

	return (
		<div
			style={{
				backgroundColor: "#6d8faa",
				width: "100%",
				height: "100%",
				position: "absolute",
			}}
		>
			<Container
				component="main"
				style={{
					maxWidth: "400px",
					width: "90%",
					marginTop: "10%",
					padding: "20px",
					backgroundColor: "white",
					borderRadius: "10px",
				}}
			>
				<CssBaseline />
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<Typography
						component="h1"
						variant="h5"
						style={{ textAlign: "center" }}
					>
						Biofield Science
						<br />
						Evidence Map
					</Typography>
					<Box
						component="form"
						onSubmit={handleSubmit}
						noValidate
						sx={{ mt: 1 }}
					>
						<TextField
							margin="normal"
							required
							fullWidth
							id="email"
							label="Email Address"
							name="email"
							autoComplete="email"
							autoFocus
							style={{ visibility: "hidden", display: "none" }}
						/>
						<TextField
							margin="normal"
							fullWidth
							name="password"
							label="Password"
							type="password"
							id="password"
							autoComplete="current-password"
							error={passwordIsCorrect === false ? true : false}
							helperText={
								passwordIsCorrect === false
									? "Incorrect password"
									: ""
							}
						/>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							sx={{ mt: 3, mb: 2 }}
							style={{ backgroundColor: "#000000" }}
						>
							VISIT PAGE
						</Button>
					</Box>
				</Box>
			</Container>
		</div>
	);
}

export default Visit;
